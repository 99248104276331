@font-face {
  font-family: "Futura";
  src: url("../../fonts/Futura-Medium.woff2") format("woff2"), url("../../fonts/Futura-Medium.woff") format("woff"),
    url("../../fonts/Futura-Medium.ttf") format("truetype"),
    url("../../fonts/Futura-Medium.svg#Futura-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("../../fonts/Futura-Bold.woff2") format("woff2"), url("../../fonts/Futura-Bold.woff") format("woff"),
    url("../../fonts/Futura-Bold.ttf") format("truetype"), url("../../fonts/Futura-Bold.svg#Futura-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "Futura";
//   src: url("../../fonts/Futura.woff2") format("woff2"), url("../../fonts/Futura.woff") format("woff"),
//     url("../../fonts/Futura.ttf") format("truetype"), url("../../fonts/Futura.svg#Futura") format("svg");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

h1,
h2,
h3 {
  font-weight: bold;
  line-height: normal;
}
@include media-breakpoint-down(sm) {
  h1 {
    &,
    * {
      font-size: 26px;
    }
  }
  h2 {
    &,
    * {
      font-size: 20px;
    }
  }
  h3 {
    &,
    * {
      font-size: 18px;
    }
  }
}

.text-small {
  font-size: 80%;
}
