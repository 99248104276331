/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size) {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    padding: 0;
    list-style: none;

    > li {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -($item-size / 2);
        width: $item-size * 2.5;
        height: $item-size * 2.5;
        img {
            width: $item-size;
            height: $item-size;
            border-radius: 50%;
        }

        $angle: (360 / $item-count);
        $rot: 272;

        @for $i from 1 through $item-count {
            &:nth-of-type(#{$i}) {
                transform: rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
            }

            &:nth-of-type(#{$i}) {
                p {
                    @if $i > 6 {
                        text-align: right;
                    }
                    @if $i == 6 or $i == 1 {
                        text-align: center;
                    }
                    transform: rotate($rot * 1deg) translate($circle-size / 4 / 2) rotate($rot * -1deg);
                }
            }

            $rot: $rot + $angle;
        }
    }
}

.circle-container {
    padding-inline-start: 0 !important;
    padding-left: 0 !important;
    margin: 0 auto;
    @include on-circle($item-count: 10, $circle-size: 400px, $item-size: 50px);
    li {
        cursor: pointer;
        img {
            display: block;
            max-width: 100%;
            transition: 0.5s;
        }
        &:hover {
            img {
                transition: 0.5s;
                transform: scale(1.2);
            }
            p span {
                text-decoration: underline;
            }
        }
        p {
            position: absolute;
            margin-top: -50px;
            margin-bottom: 0;
            width: 150px;
            color: $blue;
            line-height: 20px;
            transition: 0.5s;
            // background-color: rgba(red, 0.1);
            b {
                font-weight: bold;
                color: $blue-light;
                font-size: 22px;
            }
        }
        &:nth-of-type(1) {
            p {
                margin-top: -70px;
                left: -50px;
            }
        }
        &:nth-of-type(2) {
            p {
                left: 25px;
                margin-top: -50px;
            }
        }
        &:nth-of-type(3) {
            p {
                left: 16px;
            }
        }
        &:nth-of-type(4) {
            p {
                left: 16px;
            }
        }
        &:nth-of-type(5) {
            p {
                margin-top: -30px;
            }
        }
        &:nth-of-type(6) {
            p {
                margin-top: -40px;
                left: -50px;
            }
        }
        &:nth-of-type(7) {
            p {
                left: -65px;
                width: 90px;
            }
        }
        &:nth-of-type(8) {
            p {
                left: -65px;
                width: 100px;
            }
        }
        &:nth-of-type(9) {
            p {
                left: -85px;
                width: 120px;
            }
        }
        &:nth-of-type(10) {
            p {
                left: -65px;
                width: 80px;
            }
        }
    }
}
