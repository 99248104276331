body.start {
    section {
        h1,
        h2,
        h3 {
            margin-bottom: 20px;
            @include media-breakpoint-up(md) {
                margin-bottom: 40px;
            }
        }
    }
    .section--start-header {
        padding: 50px 0 30px;
        background: rgb(101, 119, 158);
        background: linear-gradient(140deg, rgba(101, 119, 158, 1) 0%, rgba(29, 45, 80, 1) 50%);
        color: $white;
        .headline {
            margin-bottom: 30px;
            @include media-breakpoint-up(md) {
                margin-bottom: 60px;
            }
        }
        p.fussnote {
            font-size: 12px;
            opacity: 0.7;
            margin: 0;
        }
        .blocks {
            display: flex;
            flex-direction: row;
            flex-flow: wrap;

            .block {
                margin-bottom: 10px;
                @include media-breakpoint-up(sm) {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .kreise {
                    display: flex;
                    align-items: center;
                    p.und {
                        font-size: 18px;
                        font-weight: bold;
                        margin: 0 10px;
                        @include media-breakpoint-up(md) {
                            font-size: 32px;
                            margin: 0 20px;
                        }
                    }
                    .kreis {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        background-color: #828ea8;
                        color: white;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        margin-bottom: 10px;
                        @include media-breakpoint-up(sm) {
                            margin-bottom: 20px;
                        }
                        @include media-breakpoint-up(lg) {
                            margin-bottom: 0;
                            width: 130px;
                            height: 130px;
                        }
                        p {
                            margin: 0;
                            display: block;
                            width: 100%;
                            font-weight: bold;
                            &:first-child {
                                font-size: 18px;
                                @include media-breakpoint-up(lg) {
                                    font-size: 36px;
                                }
                            }
                        }
                    }
                }
                .text {
                    @include media-breakpoint-up(sm) {
                        margin-left: 20px;
                    }
                    p {
                        font-size: 15px;
                        @include media-breakpoint-up(sm) {
                            font-size: 16px;
                        }
                        br {
                            display: none;
                            @include media-breakpoint-up(md) {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .section--services {
        position: relative;
        .carousel-item-custom {
            color: $blue-light;
        }
        .carousel-inner {
            margin-top: 40px;
            .carousel-item {
                color: $blue-light;
                &[data-index="0"] {
                    @include media-breakpoint-down(md) {
                        display: none !important;
                    }
                }
            }
        }
        .pagination {
            color: $blue-light;
            .arrows {
                margin-right: 20px;
                .carousel-control-prev,
                .carousel-control-next {
                    position: relative;
                    display: inline-block;
                    padding: 15px;
                    margin: 3px;
                }
                span {
                    cursor: pointer;
                    color: $blue-light;
                    &:hover {
                        color: $blue;
                    }
                }
            }
            .numbers {
                padding-top: 18px;
                span {
                    margin: 2px;
                }
            }
        }
        .circle-container {
            padding-inline-start: 0 !important;
            padding-left: 0 !important;
            @include media-breakpoint-down(md) {
                li {
                    list-style: none;
                    margin-bottom: 15px;
                    @include media-breakpoint-up(sm) {
                        display: flex;
                        p {
                            b {
                                margin: 0 20px;
                            }
                        }
                    }
                    p {
                        b {
                            margin: 0 10px 0;
                            font-size: 40px;
                            color: $blue-light;
                        }
                        br {
                            display: none;
                        }
                    }
                    img {
                        width: 77px;
                        height: 77px;
                    }
                }
            }
            @include media-breakpoint-up(lg) {
                background-image: url("../../images/service/pet-care-only-services-inner.jpg");
                background-size: 320px;
                background-repeat: no-repeat;
                background-position: center;
                width: 720px;
                height: 720px;
                border-radius: 50%;
                background-color: $white;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url("../../images/service/circle.jpg") no-repeat center;
                    background-size: 100%;
                    z-index: -1;
                }
            }
        }
        .text-center-circle {
            position: absolute;
            top: 43%;
            left: 39%;
            color: $white;
            text-align: center;
            text-transform: uppercase;
            p {
                font-size: 24px;
                font-weight: bold;
                span {
                    font-size: 18px;
                    font-weight: normal;
                }
            }
        }
    }
    .contact-widget {
        position: fixed;
        bottom: 260px;
        right: 0;
        color: $white;
        width: 150px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 20px;
        z-index: 9;
        p {
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 12px;
            &.text-small {
                font-weight: normal;
                margin-bottom: 10px;
            }
        }
        a {
            &:hover {
                text-decoration: none;
                img {
                    transition: 0.5s;
                    transform: scale(1.3);
                }
            }
            img {
                max-width: 40px;
                transition: 0.5s;
                &.telefon {
                    margin-right: 15px;
                }
            }
        }
    }
    .section--about {
        padding: 50px 0 30px;
        background: rgb(101, 119, 158);
        background: linear-gradient(140deg, rgba(101, 119, 158, 1) 0%, rgba(29, 45, 80, 1) 50%);
        iframe {
            width: 100%;
            height: 200px;
            margin-bottom: 30px;
            @include media-breakpoint-up(sm) {
                height: 300px;
            }
            @include media-breakpoint-up(md) {
                height: 350px;
            }
            @include media-breakpoint-up(xl) {
                height: 400px;
                padding-left: 50px;
            }
        }
        .tasks {
            div {
                margin-bottom: 10px;
                @include media-breakpoint-up(sm) {
                    text-align: center;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 14px;
                    margin: 0;
                }
                img {
                    max-height: 75px;
                    width: auto;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .section--products {
        padding: 50px 0 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center left;
        text-shadow: 0px ​2px 15px $white;
        @include media-breakpoint-up(sm) {
            background-position: top right 10%;
        }
        @include media-breakpoint-up(md) {
            background-position: top right;
        }
        color: $blue;
        h2 {
            margin-bottom: 20px;
            @include media-breakpoint-up(md) {
                margin-bottom: 40px;
            }
        }
        .odd {
            .text_hover {
                @include media-breakpoint-down(sm) {
                    left: 0;
                }
            }
        }
        .title {
            text-align: center;
            margin-top: 15px;
            text-shadow: 2px 3px 22px $white;
        }
        .image {
            cursor: pointer;
            position: relative;
            width: 100%;
            max-width: 65%;
            text-align: center;
            margin: 0 auto;
            img {
                border-radius: 50%;
            }
            &:hover {
                .text_hover {
                    display: block;
                }
            }
        }
        .text_hover {
            background: rgba($black, 0.9);
            color: $white;
            padding: 60px 25px 25px;
            @include media-breakpoint-up(lg) {
                padding: 25px;
            }
            font-size: 14px;
            border-radius: 30px;
            border-top-left-radius: 0;
            position: absolute;
            top: 0;
            display: none;
            z-index: 1;
            width: 300px;
            @include media-breakpoint-down(md) {
                left: -70px;
            }
            @include media-breakpoint-down(sm) {
                left: -200px;
            }
            &.off {
                display: none !important;
            }
            &.on {
                display: block;
            }
            p {
                margin: 0;
            }
            .close_hover {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
                color: $white;
                position: absolute;
                right: 20px;
                top: 10px;
                font-size: 35px;
                cursor: pointer;
            }
        }
    }
    .section--darreichungsformen {
        padding: 30px 0;
        color: $white;
        .image {
            border-radius: 50%;
            margin: 0 auto 20px;
            overflow: hidden;
            box-shadow: 10px 8px 13px $black;
            max-width: 60%;
            text-align: center;
        }
        @include media-breakpoint-up(lg) {
            .col-lg-2-custom {
                flex: 0 0 20%;
                max-width: 21%;
            }
        }
    }
    .section--anwendungsbereiche {
        padding: 50px 0 30px;
        .circle {
            width: 120px;
            height: 120px;
            border-width: 4px;
            border-style: solid;
            border-color: $blue-light;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:hover,
            &.on {
                &::after {
                    transition: 0.5s;
                    left: 0;
                }
            }
            * {
                margin: 0;
            }
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: -100%;
                right: 0;
                border-radius: 50%;
                transition: 0.5s;
                background-color: $blue-light;
            }
            &.katze {
                &::after {
                    background-image: url("../../images/icon-katze.png");
                    background-size: 65px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            &.hund {
                &::after {
                    background-image: url("../../images/icon-hund.png");
                    background-size: 65px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            &.hund.katze {
                &::after {
                    background-image: url("../../images/icon-katze-hund.png");
                    background-size: 100px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .col-lg-2-custom {
                flex: 0 0 20%;
                max-width: 21%;
            }
        }
    }
    .section--zusatznutzen {
        padding: 50px 0 30px;
        .image {
            margin: 0 auto;
            width: 120px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 30px;
            @include media-breakpoint-up(sm) {
                width: 140px;
            }
        }
    }
    .section--verpackungsmoeglichkeiten {
        padding: 50px 0;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-up(lg) {
            background-position: center right;
        }

        background-repeat: no-repeat;
        @include media-breakpoint-up(lg) {
            .col-lg-2-custom {
                flex: 0 0 20%;
                max-width: 21%;
            }
        }
        .title {
            margin-top: 10px;
        }
        .icon {
            min-height: 60%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            img {
                height: 100px;
                width: auto;
                &.icon-1,
                &.icon-2 {
                    width: 100px;
                    height: auto;
                }
            }
        }
    }
}

.custom-list-item {
    ul,
    ol {
        padding-left: 30px;
        li {
            list-style: none;
            &:before {
                content: "✓";
                margin-right: 18px;
                margin-left: -30px;
                color: $blue;
            }
        }
    }
}
