header.site-header {
    max-height: 200px;
    .nav-item {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        &.locale {
            margin-top: 7px;
            a {
                &.active {
                    font-weight: bold;
                }
            }
        }
    }
    .navbar {
        border-bottom: 1px solid $blue-light;
    }
    .navbar-toggler {
        width: 46px;
        height: 30px;
        padding: 0;
        margin: 0;
        .line1,
        .line2,
        .line3 {
            width: 100%;
            height: 4px;
            background-color: $blue;
            margin-bottom: 7px;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 10px;
        }
        .line3 {
            margin-bottom: 0;
        }
        &[aria-expanded="true"] {
            ul {
                overflow: scroll;
            }
            .line2 {
                display: none;
            }
            .line3 {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                transform: rotate(45deg);
                margin: 0;
                transform-origin: 28px 3px;
                // margin-top: -8px;
            }
            .line1 {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                transform: rotate(-45deg);
                margin: 0;
                transform-origin: top center;
            }
        }
    }
}

a.navbar-brand {
    max-width: 200px;
    width: 100%;
    height: auto;
    @include media-breakpoint-up(sm) {
        max-width: 220px;
    }
    @include media-breakpoint-up(xl) {
        max-width: 280px;
    }
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}
