footer {
    height: auto;
    text-align: center;
    padding: 20px;
    @include media-breakpoint-up(sm) {
        padding: 30px 0;
        min-height: 80px;
        margin-top: -80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    * {
        color: $white;
    }
    ul {
        padding: 0;
        margin: 0;
        display: block;
        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-around;
        }
        li {
            display: block;
            margin: 10px 0;
            @include media-breakpoint-up(md) {
                display: inline-block;
                margin: 0 20px;
            }
            a {
                &:hover {
                    color: $white;
                }
            }
        }
    }
}
