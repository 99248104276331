/*body {
  font-family: sans-serif;
}*/

.cookie-banner-settings {
    background-color: #fff;
    padding: 20px;
    position: fixed;
    left: 20px;
    bottom: 60px;
    transform: translate(0px, -7%);
    z-index: 100;
    width: 360px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid $primary !important;

    a {
        color: blue;
    }

    .cookie-settings {
        .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: $primary !important;
            background-color: $primary !important;
        }

        .custom-switch{
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .cookie-button {
        button.section-button {
            background: $primary 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #d8292f26;
            opacity: 1;
            border: 1px solid $primary !important;
            border-radius: 0.25rem;
            padding: 5px 12px;
            color: $white;

            &:hover {
                background-color: $white;
                color: $primary;
                border: 1px solid $dark !important;
            }
        }
    }
}

.cookie-banner {
    position: fixed;
    bottom: 60px;
    left: 20px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    width: 500px;
    z-index: 100;
    border: 1px solid $primary !important;

    @include media-breakpoint-down(sm) {
        left: 0px;
        bottom: 0px;
        width: auto;

        .cookie-button {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
        }
    }

    .cookie-message {
        .col-7{
            margin-left: -23px;
        }
        a {
            color: blue;
        }

        img {
            width: auto !important;
            height: 64px !important;
            padding: 4px;
            align-self: center;
        }
    }

    .cookie-button {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        button.section-button {
            background: $primary 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #d8292f26;
            opacity: 1;
            border: 1px solid $primary !important;
            border-radius: 0.25rem;
            padding: 6px 15px;
            color: $white;

            &:hover {
                background-color: $white;
                color: $primary;
                border: 1px solid $dark !important;
            }
        }

        button.section-button-secondary {
            background: $white;
            border: 1px solid $dark;
            border-radius: 0.25rem;
            opacity: 1;
            padding: 6px 15px;
            color: $dark;

            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
    }
}

.cookie-banner-min {
    position: fixed;
    bottom: -27px;
    background-color: $primary;
    z-index: 1000;
    transition: 300ms ease-in-out;
    display: none;

    &:hover {
        bottom: 0px;
        transition: 300ms ease-in-out;
    }

    button.section-button {
        background: $primary 0% 0% no-repeat padding-box;
        box-shadow: 4px -2px 10px rgba(0, 0, 0, 0.15);
        opacity: 1;
        border: 1px solid $dark;
        padding: 6px 15px;
        color: $white;
        &:focus {
            outline:0;
        }
    }
}

.thumbnail-custom{

    position: relative;
    width: 100%;
    padding: 20px;

    .overlay {
        position: absolute;
        background: rgba(220, 220, 220, 0.9);
        left: 6%;
        right: 6%;
        bottom: 10%;
        top: 10%;
        margin: 10px;
        padding: 10px;
        color: black;
        font-size: large;
        p{
            a{
                text-decoration: underline;
            }
            padding: 22px 8px 0px 8px;
            text-align: center;
        }
    }

    button {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background: rgba(29, 45, 80, 1);
        color: white;
        font-size: 16px;
        padding: 6px 14px;
        border: 1px solid $dark;
        border-radius: 0.25rem;
        cursor: pointer;
        text-align: center;

        &:hover {
            background-color: gainsboro;
            color: #1d2d50;
        }
    }
}