// $container-max-widths: (
//     sm: 540px,
//     md: 720px,
//     lg: 1140px,
//     xl: 1200px,
// ) !default;

@import "./components/variables";
@import "~bootstrap/scss/bootstrap";
@import "./components/typo";

@import "./components/global";

@import "./components/footer";
@import "./pages/start.scss";
@import "./components/header";
@import "./components/navi";
@import "./components/cookies";
// @import "./components/colors";

@include media-breakpoint-up(lg) {
    @import "./components/circle-container";
}

// Anchor für moderne Browser
:target {
    display: block;
    position: relative;
    top: -110px;
    visibility: hidden;
}