html {
    position: relative;
    height: 100%;
    min-height: 100%;
}

body {
    #content {
        padding-top: 110px;
        min-height: 100vh;
        padding-bottom: 80px;
        margin-bottom: -80px;
        overflow-y: hidden;
    }
    #rmOrganism {
        * {
            font-family: "Futura" !important;
        }
        .rmSubscription .rmBase__el .rmBase__compLabel {
            display: none !important;
        }
        .rmBase__container {
            padding: 50px 0 !important;
        }
        .rmLayout--banner.rmBase .rmBase__content {
            padding-right: 15px !important;
            padding-left: 15px !important;
            @media (min-width: 1200px) {
                max-width: 1320px !important;
            }
        }
        .rmBase__el .comp__input,
        .rmBase__el .comp__select {
            border-radius: 5px !important;
            box-shadow: none !important;
            border: 1px solid $blue;
        }
        .rmPopup__container {
            .rmPopup .rmPopup__close {
                background: none !important;
                color: $blue !important;
                font-size: 36px !important;
            }
            @media (max-width: 400px) {
                z-index: 9999;
                .rmPopup.rmPopup--banner.rmLayout--banner-bottom {
                    min-height: 100vh;
                    height: 100%;
                    width: 100%;
                    overflow: scroll;
                }
            }
        }
    }
}
img {
    max-width: 100%;
}

.back-to-top {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 120px;
    right: 20px;
    cursor: pointer;
    font-size: 26px;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 10px $white;
    @include media-breakpoint-up(lg) {
        font-size: 32px;
        width: 70px;
        height: 70px;
    }
}

.section--contactform {
    input,
    textarea {
        border-radius: 0;
    }
    textarea {
        height: 175px;
    }
}

.section--lexikon {
    .long,
    .short {
        display: none;
        span {
            cursor: pointer;
            color: $blue-light;
            margin-left: 10px;
            &:hover {
                text-decoration: underline;
            }
        }
        &.on {
            display: block;
            p {
                margin-bottom: 0;
            }
        }
    }
    p.letter {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: 0;
        font-weight: bold;
        text-align: center;
        border: 1px solid $blue-light;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 20px;
        position: absolute;
        top: -6px;
        @include media-breakpoint-up(sm) {
            top: -14px;
            width: 70px;
            height: 70px;
            font-size: 32px;
        }
    }
}

.section--news {
    .article {
        margin-bottom: 40px;
        hr {
            border-top: 5px dotted $blue-light;
            margin-top: 40px;
        }
        @include media-breakpoint-down(md) {
            img {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
    .detail {
        .image-wrapper {
            width: 100%;
            max-width: 40%;
            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 100%;
                margin-bottom: 40px;
                padding: 0;
                img {
                    width: 100%;
                }
            }
        }
    }
    p.date {
        font-size: 16px;
        margin: 0;
        font-weight: normal !important;
    }
}

.section--news {
    iframe {
        min-width: 50%;
        height: 500px;
    }
}

.dynamic_contactform1_checkbox{
    label{position: absolute;left:40px;}
}

.nickname{
    position: absolute;
    top: -2000px;
}